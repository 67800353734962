import { Lightning } from '@lightningjs/sdk'
import SeekablePlayerControls from '../../../components/player/PlayerControls/SeekablePlayerControls'
import BasePlayer from '../BasePlayer'
import {
  ProgressHookType,
  createProgressHookEvent,
  useProgress,
} from '../../../components/player/PlayerControls/hooks/useProgress'
import { inRange } from 'lodash'

class HasSeeking extends BasePlayer {
  override _controls: SeekablePlayerControls
  _progress: ProgressHookType
}

enum SeekDirection {
  NONE = '',
  FF = 'ff',
  REW = 'rew',
}

export const WithSeeking = <T extends Lightning.Component.Constructor<HasSeeking>>(component: T) =>
  class extends component {
    TIME_STEPS = 10
    _seekDirection = SeekDirection.NONE

    override _init() {
      super._init()
      this._progress = useProgress()
    }

    $forward() {
      this._controls.seekIcon = 1
      this._updateTimeOffset(1)

      if (this._seekDirection !== SeekDirection.FF) {
        this._seekDirection = SeekDirection.FF
        this._analyticsDelegate?.fireClick('Forward')
        this._analyticsDelegate?.fireSeekStart()
      }
    }

    $rewind() {
      this._controls.seekIcon = -1
      this._updateTimeOffset(-1)

      if (this._seekDirection !== SeekDirection.REW) {
        this._seekDirection = SeekDirection.REW
        this._analyticsDelegate?.fireClick('Rewind')
        this._analyticsDelegate?.fireSeekStart()
      }
    }

    $seek() {
      const currentTime = this._progress.value[0]
      // seek adjust is used for SLEs currently.
      // add time required to get the player in line with the seek bar (seconds).
      const seekAdjust = this._progress.value[3] || 0
      this._player?.seek(currentTime + seekAdjust)
    }

    /**
     * Rewinds content 5 seconds before the end.
     * Used in non-production environments.
     */
    $seekToTheEnd() {
      this._player?.pause()
      this._controls.seekIcon = 1
      const duration = this._progress.value[1]
      this._progress.set(createProgressHookEvent(duration - 5))
      this.$seek()
    }

    _updateTimeOffset = (offset: 1 | -1) => {
      if (this._player?.isPlaying()) this._player?.pause()
      const [current, duration, seekableRange] = this._progress.value
      const time = current + this.TIME_STEPS * offset
      if (!inRange(time, seekableRange?.[0] || 0, seekableRange?.[1] || duration)) return
      this._progress.set(createProgressHookEvent(time))
    }

    override _onSeekEnd() {
      super._onSeekEnd()
      this._seekDirection = SeekDirection.NONE
      this._analyticsDelegate?.fireSeekEnd()
    }
  }
