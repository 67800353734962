export enum PlayerControlsStates {
  PlayOrPause = 'PlayOrPause',
  AdControls = 'AdControls',
  PauseAdControls = 'PauseAdControls',
  Seeking = 'Seeking',
  MoreInfo = 'MoreInfo',
  SkipButton = 'SkipButton',
  LiveToVod = 'LiveToVod',
  TempPass = 'TempPass',
  MediaOptions = 'MediaOptions',
  QOSPanel = 'QOSPanel',
  LiveControls = 'LiveControls',
}

export enum LiveGuideV2States {
  Tabs = 'Tabs',
  Channels = 'Channels',
  Filters = 'Filters',
  Sports = 'Sports',
  AssetInfo = 'AssetInfo',
}

export enum PlayerStates {
  Ready = 'Ready',
  LPPlayer = 'LPPlayer',
  Epg = 'Epg',
  PlayerInActive_Yes = 'PlayerInActive.Yes',
  PlayerInActive_No = 'PlayerInActive.No',
  LPPlayer_Controls = 'LPPlayer.Controls',
  LPPlayer_Controls_Seeking = 'LPPlayer.Controls.Seeking',
  LPPlayer_LiveToVod = 'LPPlayer.LiveToVod',
  LPPlayer_MediaOptions = 'LPPlayer.MediaOptions',
  LPPlayer_TempPass = 'LPPlayer.TempPass',
  LPPlayer_SkipButton = 'LPPlayer.SkipButton',
  PlayerEndCard = 'PlayerEndCard',
  PlayerEndCard_MiniPlayer = 'PlayerEndCard.MiniPlayer',
  SFVODEndCard = 'SFVODEndCard',
  PlayerEndcardFER = 'PlayerEndCardFER',
}

export enum FiltersStates {
  FiltersList = 'FiltersList',
  Content = 'Content',
}

export enum FiltersContentStates {
  Container = 'Container',
  Error = 'Error',
}

export enum EventsWithFiltersStates {
  Filters = 'Filters',
  Results = 'Results',
  Error = 'Error',
}

export enum ShowsStates {
  BrandLabelsList = 'BrandLabelsList',
  Error = 'Error',
  LinksSelectableGroup = 'LinksSelectableGroup',
  LoadSelectedBrandData = 'LoadSelectedBrandData',
}

export enum MyProfileStates {
  LogoutConfirmation = 'LogoutConfirmation',
  LogoutConfirmation_Notification = 'LogoutConfirmation.Notification',
  NBCUserProfile = 'NBCUserProfile',
  TvProvider = 'TvProvider',
}

export enum MainStates {
  Featured = 'Featured',
  Scroller = 'Scroller',
}
