import { Lightning, Log, Registry } from '@lightningjs/sdk'
import { debounce } from 'lodash'
import { Subject } from 'rxjs'

import SpeechController from './SpeechController'
import { AnnouncementType } from './types'

type LightningComponent = Lightning.Element<
  Lightning.Component.TemplateSpecLoose,
  Lightning.Component.TypeConfigLoose
>

type ComponentWithAnnounce = LightningComponent & {
  announce?: AnnouncementType
  title?: string
  announceContext?: AnnouncementType
  label?: string
  loading?: boolean
}

type AnnouncerEventDef = { type: AnnouncerEvent }

export enum AnnouncerEvent {
  TTS_START = 'announceStarted',
  TTS_END = 'announceEnded',
}

const ANNOUNCER_TAG = 'Announcer'

class Announcer {
  public enabled = false
  public debug = false
  private readonly announcerTimeout = 300 * 1000
  private readonly announcerFocusDebounce = 400
  private readonly voiceOutTimeout = 100
  private voiceOutDisabled = false
  private readonly speechController = SpeechController
  private prevFocusPath: ComponentWithAnnounce[] = []
  private _events: Subject<AnnouncerEventDef> = new Subject<AnnouncerEventDef>()

  static getElementName(elm: LightningComponent): string {
    return elm.ref || elm.constructor.name
  }

  announce(
    toAnnounce: AnnouncementType,
    { append = false, notification = false }: { append?: boolean; notification?: boolean } = {}
  ): void {
    if (!this.enabled) return
    if (this.voiceOutDisabled && notification) this.voiceOutDisabled = false
    this.debounceAnnounceFocusChange.flush()
    if (append && this.speechController.active) {
      this.speechController.append(toAnnounce)
    } else {
      this.cancel()
      this.voiceOut(toAnnounce)
    }

    if (notification) {
      this.voiceOutDisabled = true
    }
  }

  onFocusChange(focusPath: ComponentWithAnnounce[] = []): void {
    if (!this.enabled) return
    const lastFocusPath: ComponentWithAnnounce[] = this.prevFocusPath || []
    const loaded = focusPath.every((elm) => !elm.loading)
    const focusDiff = focusPath.filter((elm) => !lastFocusPath.includes(elm))

    if (!loaded) {
      this.debounceAnnounceFocusChange()
      return
    }

    this.prevFocusPath = focusPath.slice(0)

    const orderedAnnouncement: Array<[string, string, AnnouncementType]> = focusDiff.reduce(
      (acc, elm) => {
        const elName = Announcer.getElementName(elm)
        if (elm.announce) {
          acc.push([elName, 'Announce', elm.announce])
        } else if (elm.title) {
          acc.push([elName, 'Title', elm.title || ''])
        } else {
          acc.push([elName, 'Label', elm.label || ''])
        }
        return acc
      },
      [] as Array<[string, string, AnnouncementType]>
    )

    focusDiff.reverse().reduce((acc, elm) => {
      const elName = Announcer.getElementName(elm)
      if (elm.announceContext) {
        acc.push([elName, 'Context', elm.announceContext])
      } else {
        acc.push([elName, 'No Context', ''])
      }
      return acc
    }, orderedAnnouncement)

    if (this.debug) Log.info(ANNOUNCER_TAG, orderedAnnouncement)

    const toAnnounce = orderedAnnouncement.reduce(
      (acc, a) => {
        //@ts-expect-error TS2345 Arugment of type 'AnnouncmentType' is not assignable to type 'string | string[]'
        if (a[2]) acc.push(a[2])
        return acc
      },
      [] as Array<string | Array<string>>
    )

    if (toAnnounce.length) {
      const resultAnnounce = toAnnounce.reduce((acc: string[], val) => {
        if (Array.isArray(val)) {
          acc = acc.concat(val)
        } else {
          acc.push(val)
        }
        return acc
      }, [] as string[])
      if (this.voiceOutDisabled) {
        if (!this.speechController.active) {
          this.voiceOutDisabled = false
        } else {
          this.speechController.append(resultAnnounce)
          return
        }
      }
      this.stop()
      this.voiceOut(resultAnnounce)
    }
  }

  public get events(): Subject<AnnouncerEventDef> {
    return this._events
  }

  public subscribe(handler: any) {
    if (this.enabled) return this._events.subscribe(handler)
  }

  public emit(type: AnnouncerEvent) {
    this.events.next({
      type,
    })
  }

  public stop(): void {
    this.announceEnded()
    this.resetFocusTimer()
    this.cancel()
  }

  private voiceOut(toAnnounce: AnnouncementType): void {
    if (this.voiceOutDisabled) return
    this.announceEnded()

    // timeout is required otherwise cancel isn't working properly
    Registry.setTimeout(() => {
      this.emit(AnnouncerEvent.TTS_START)
      this.speechController
        .speak(toAnnounce)
        .then(this.announceEnded)
        .catch(this._onError)
        .finally(() => {
          this.announceEnded()
          this.voiceOutDisabled = false
        })
    }, this.voiceOutTimeout)
  }

  private _onError = (error: any): void => {
    this.announceEnded()
    // Won't TVPlatform.reportError since it will send a lot of unneeded events
    if (this.debug) Log.error(ANNOUNCER_TAG, error)
  }

  private announceEnded = (): void => {
    this.emit(AnnouncerEvent.TTS_END)
  }

  private debounceAnnounceFocusChange = debounce(
    this.onFocusChange.bind(this),
    this.announcerFocusDebounce,
    { leading: false, trailing: false }
  )

  private resetFocusTimer = debounce(
    () => {
      this.prevFocusPath = []
    },
    this.announcerTimeout,
    { leading: false, trailing: false }
  )

  private cancel(): void {
    this.speechController.cancel()
  }
}

export default new Announcer()
