import { Lightning } from '@lightningjs/sdk'
import { NonLinearAdEvent } from '@sky-uk-ott/core-video-sdk-js'
import { PlayerControlsStates } from '../../../../constants'
import { PauseAdControlsStateFactory } from '../states/PauseAdControl'
import SeekablePlayerControls from '../SeekablePlayerControls'

export type ControlsHasPauseAds = {
  pauseAdEventData: any
  pauseAdRefreshData: any
  setShowPauseAds(): void
  shouldShowPauseAds(): boolean
  setPauseAdsEventData(adEvent: NonLinearAdEvent, refreshData: Record<string, any>): void
  clearPauseAdsEventData(): void
}

export const WithPauseAds = <T extends Lightning.Component.Constructor<SeekablePlayerControls>>(
  base: T
) =>
  class extends base implements ControlsHasPauseAds {
    _pauseAdCurrent: NonLinearAdEvent | null = null
    _pauseAdRefreshData: any | undefined

    static _states() {
      // @ts-expect-error super doesn't work with static methods
      return [...super._states(), PauseAdControlsStateFactory(this)]
    }

    get pauseAdEventData() {
      return this._pauseAdCurrent
    }
    set pauseAdRefreshRate(refreshData: Record<string, any>) {
      this._pauseAdRefreshData = refreshData
    }

    get pauseAdRefreshData() {
      return this._pauseAdRefreshData
    }

    setShowPauseAds() {
      this._setState(PlayerControlsStates.PauseAdControls)
    }

    shouldShowPauseAds() {
      // Don't give back pause ads data when seeking or in an ad
      return !(
        this._pauseAdCurrent === null ||
        this._getState() === PlayerControlsStates.AdControls ||
        this._getState() === PlayerControlsStates.Seeking ||
        this.fireAncestors('$isPlaying')
      )
    }

    setPauseAdsEventData(adEvent: NonLinearAdEvent, refreshData: Record<string, any>) {
      this._pauseAdCurrent = adEvent
      this.pauseAdRefreshRate = refreshData
    }

    clearPauseAdsEventData() {
      this._pauseAdCurrent = null
    }
  }
