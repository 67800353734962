import SeekablePlayerControls from './SeekablePlayerControls'

import FERStrategy from './strategies/FERStrategy'
import { formatSecondsAsHhMmSs, setSmooth } from '../../../helpers'
import { COLORS, FONT_FACE, PlayerControlsStates, VERTICAL_ALIGN } from '../../../constants'
import { WithPauseAds } from './hoc/WithPauseAds'
import { WithTrickPlay } from './hoc/WithTrickPlay'
import IPlayerControlsStrategy from './strategies/IPlayerControlsStrategy'
import { WithAdBreaks } from './hoc/WithAdBreaks'
import { ProgressHookEvent } from './hooks/useProgress'
import TempPassCTA from './TempPassCTA'
import { sendMetric } from '../../../lib/analytics/Analytics'
import { EVENTS } from '../../../lib/analytics/types'
import { activateTempPassRedirect } from '../../../pages/Player/StreamLoader/actions/redirect'
import { TEMP_PASS_REDIRECT } from '../../../pages/Player/BasePlayer'
import { Registry } from '@lightningjs/sdk'
import { useRouterBackDisabled } from '../../../widgets/Modals/activation/hooks/useRouterBackDisabled'

const TIMEOUT_HIDE_TEMPPASS_TIMER = 5000

class FERPlayerControls extends SeekablePlayerControls {
  override _strategy: IPlayerControlsStrategy = new FERStrategy(this)
  static override TITLE_CONTAINER_Y = 700
  _tempPassControlsOverlay: any

  static override _template() {
    return super._template({
      PlayerControls: {
        TitleContainer: {
          HeadingContainer: {
            Title: {
              x: 18,
            },
          },
          TertiaryTitle: {
            y: 150,
            x: 82,
            text: {
              textColor: COLORS.white,
              verticalAlign: VERTICAL_ALIGN.middle,
              fontSize: 28,
              fontFace: FONT_FACE.light,
              maxLines: 1,
              wordWrapWidth: FERPlayerControls.PROGRESSBAR_TOTAL_WIDTH,
            },
          },
        },
      },
    })
  }

  override _updateProgress([currentTime, duration]: ProgressHookEvent) {
    if (!duration) return
    const position = (currentTime * FERPlayerControls.PROGRESSBAR_TOTAL_WIDTH) / duration
    this._aTime.text.text = formatSecondsAsHhMmSs(currentTime)
    setSmooth(this.tag('CurrentBar'), 'w', position, { duration: 0.2 })
    setSmooth(this.tag('SeekBar'), 'x', position, { duration: 0.2 })
  }

  override getTitleContainerY() {
    return FERPlayerControls.TITLE_CONTAINER_Y
  }

  patchTempPassTemplate() {
    this.patch({
      TempPass: {
        type: TempPassCTA,
        alpha: 1,
      },
    })
    this._tempPassControlsOverlay = this.tag('TempPass')
    this.tempPassCtaClicked = false
  }

  createTimeOut() {
    Registry.setTimeout(() => {
      this.fireAncestors('$hideTempPassTimer')
    }, TIMEOUT_HIDE_TEMPPASS_TIMER)
  }

  updateTempPassCountdown(secondsLeft: any) {
    if (secondsLeft <= 30 && this._getState() !== PlayerControlsStates.TempPass) {
      this.fireAncestors('$showTempPassTimer', { pin: true })
    }
    this._tempPassControlsOverlay.update(secondsLeft)
  }

  static override _states() {
    return [
      ...super._states(),
      class TempPass extends this {
        override _handleEnter() {
          sendMetric(EVENTS.CLICK, {
            name: 'TempPass - Link TV Provider',
          })

          this.tempPassCtaClicked = true
          useRouterBackDisabled().set(true)
          activateTempPassRedirect(TEMP_PASS_REDIRECT.TEMP_PASS_MVPD)
        }
        override _getFocused() {
          return this.tag('TempPass') || this
        }
      },
    ]
  }
}

// Workaround for TS types not being correctly asserted with HOCs
export default class extends WithAdBreaks(WithPauseAds(WithTrickPlay(FERPlayerControls))) {}
