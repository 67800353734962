import { Lightning, Router } from '@lightningjs/sdk'
import { RouterPage } from '../../../types/global'
import { IShows } from './types'

import RouterUtil from '../../util/RouterUtil'
import LinksSelectableGroup from '../../components/LinksSelectableGroup'

import { BrandSelectableSpawner } from '../../api/spawners'
import { sendMetric } from '../../lib/analytics/Analytics'
import { pushHash } from '../../helpers'
import { setSmooth } from '../../helpers'

import { EVENTS } from '../../lib/analytics/types'
import { COLORS, ROUTE, ShowsStates } from '../../constants'

// states
import { BrandsLabelsStateFactory } from './states/BrandsLabelsStateFactory'
import { LinksSelectableStateFactory } from './states/LinksSelectableStateFactory'
import { LoadSelectedBrandStateFactory } from './states/LoadSelectedBrandStateFactory'
import { useRequest } from '../../lib/useRequest'
import { ShowsPageRequestConfig } from './request'
import { BffPage } from '../../graphql/mappers/bffPage'
import { WithRequestError, WithRequestErrorState } from '../hoc/withRequestError'

class Shows extends Lightning.Component<any, RouterPage> implements IShows {
  _brandSelectableSpawner: BrandSelectableSpawner | undefined
  _selectedBrandIndex = 0
  _initialBrand: string | undefined
  _initialCategory: string | undefined
  _currentCategory: string | undefined
  _previousItemSelectedIndex: number | undefined
  sections: any[] = []

  static override _template() {
    return {
      BrandLabelsList: {},
      LinksSelectableGroup: {
        x: 75,
        y: 386,
        type: LinksSelectableGroup,
        alpha: 0,
      },
    }
  }

  override _active() {
    this.stage.setClearColor(COLORS.dark)

    if (this.sections) {
      setSmooth(this.widgets.loader, 'visible', 0)
    }

    if (!this._initialBrand) {
      const hash = Router.getActiveHash() || ''
      const [base = ''] = hash.split('/')
      pushHash(`${base}/all`)
    }
  }

  async load(): Promise<void> {
    setSmooth(this.widgets.loader, 'visible', 1)
    try {
      const data = (await useRequest(ShowsPageRequestConfig()).fetch()) as BffPage
      this._brandSelectableSpawner = new BrandSelectableSpawner(data.sections)
      this.brandLabelsList = this._brandSelectableSpawner.createBrandLabelsList(
        this.stage,
        this._initialBrand
      )
      if (this._brandSelectableSpawner.initiallySelected !== 0)
        this.brandLabelsList.setIndex(this._brandSelectableSpawner.initiallySelected)
      this._selectedBrandIndex = this._brandSelectableSpawner.initiallySelected
      this.sections = data.sections
      this._setState(ShowsStates.LoadSelectedBrandData)
    } catch (e) {
      this._setState(WithRequestErrorState)
    } finally {
      setSmooth(this.widgets.loader, 'visible', 0)
    }
  }

  override set params(params: any) {
    this._initialBrand = params?.brand
    this._initialCategory = params?.category
  }

  set brandLabelsList(v) {
    v.x = 75
    v.y = 143
    this.patch({
      BrandLabelsList: v,
    })
  }

  get brandLabelsList() {
    return this.tag('BrandLabelsList')
  }

  get linksSelectableGroup() {
    return this.tag('LinksSelectableGroup')
  }

  get currentBrand() {
    const brand =
      this.brandLabelsList?.items?.[this.brandLabelsList?._activeIndex || 0]?.item?.obj?.data
        ?.machineName || ''
    return brand.replace('-', '').toLowerCase()
  }

  get currentCategory() {
    return this._currentCategory || ''
  }

  set currentCategory(category: string) {
    this._currentCategory = category
  }

  _parseCategory(category: string | undefined): string {
    return (category || '').replace(/&/g, '-').replace(/\s+/g, '').toLowerCase()
  }

  $selectedLinkChanged(category: { prev: string; new: string }) {
    const hash = Router.getActiveHash() || ''
    const [base = ''] = hash.split('/')
    if (base) {
      this.currentCategory = category.new
      pushHash(`${base}/${this.currentBrand}/${this._parseCategory(category.new)}`)
    }
    // Fire mparticle event as if Category change were a page view.
    sendMetric(EVENTS.PAGE_LOAD, {
      ...RouterUtil.showsBrandRoutingData(
        category.new,
        category.prev,
        true,
        this._brandSelectableSpawner?.getBrandLabel(this._selectedBrandIndex)
      ),
      path: ROUTE.shows,
    })
    sendMetric(EVENTS.CLICK, {
      name: `Category Logo Click (${category.new})`,
    })
  }

  override historyState(params: any) {
    if (params) {
      this._initialCategory = params.category
      this._initialBrand = params.brand
      this._previousItemSelectedIndex = params.selectedIndex
    } else {
      return {
        brand: this.currentBrand,
        category: this._parseCategory(this.currentCategory),
        selectedIndex: this.linksSelectableGroup?.getDataElement()?.index || null,
      }
    }
  }

  static override _states() {
    return [
      BrandsLabelsStateFactory(this),
      LinksSelectableStateFactory(this),
      LoadSelectedBrandStateFactory(this),
    ]
  }
}

export default WithRequestError(Shows)
