import { Storage } from '@lightningjs/sdk'

import {
  formatAppVersion,
  getConfigName,
  getIsDayZero,
  getMpid,
  getSportsPageName,
  getTimezone,
} from '../helpers'
import { LANGUAGES, STORAGE_KEYS } from '../constants'
import version from '../version'
import TvPlatform from '../lib/tv-platform'
import AuthenticationSingleton from '../authentication/Authentication'
import LaunchDarklySingleton from '../lib/launchDarkly/LaunchDarkly'
import LaunchDarklyFeatureFlags from '../lib/launchDarkly/LaunchDarklyFeatureFlags'
import {
  BonanzaPageQueryVariables,
  ComponentsForPlaceholdersQueryVariables,
  EntityPageType,
  VideoType,
  NbcuBrands,
  QueryNames,
  ScrollFromSeasonQueryVariables,
} from './generated/types'
import { nationalBroadcastType, nbcAffiliateName, telemundoAffiliateName } from '../api/Live'

type CommonVariables = Pick<
  BonanzaPageQueryVariables,
  | 'app'
  | 'name'
  | 'type'
  | 'userId'
  | 'platform'
  | 'ld'
  | 'timeZone'
  | 'oneApp'
  | 'language'
  | 'authorized'
  | 'isDayZero'
> & { appVersion: number }

type CoastVariables = Pick<BonanzaPageQueryVariables, 'nationalBroadcastType' | 'nbcAffiliateName'>

type TelemundoVariables = Pick<BonanzaPageQueryVariables, 'telemundoAffiliateName'>

export const Variables = {
  homePageVariables() {
    return {
      ...this.getCommonVariables(EntityPageType.Page, 'homepage'),
      ...this.getCoastValues(nationalBroadcastType(), nbcAffiliateName()),
      ...this.getTelemundoAffiliateName(telemundoAffiliateName()),
      queryName: QueryNames.BonanzaPage,
    }
  },
  sportsPageVariables() {
    return {
      ...this.getCommonVariables(EntityPageType.SportLanding, getSportsPageName()),
      ...this.getCoastValues(nationalBroadcastType(), nbcAffiliateName()),
      ...this.getTelemundoAffiliateName(telemundoAffiliateName()),
      queryName: QueryNames.BonanzaPage,
    }
  },
  olympicsPageVariables() {
    return {
      ...this.getCommonVariables(EntityPageType.OlympicsPage, 'homepage'),
      ...this.getCoastValues(nationalBroadcastType(), nbcAffiliateName()),
      ...this.getTelemundoAffiliateName(telemundoAffiliateName()),
      queryName: QueryNames.BonanzaPage,
    }
  },
  olympicsContentHubPageVariables(name: string) {
    return {
      ...this.getCommonVariables(EntityPageType.OlympicsContentHub, name),
      ...this.getCoastValues(nationalBroadcastType(), nbcAffiliateName()),
      ...this.getTelemundoAffiliateName(telemundoAffiliateName()),
      queryName: QueryNames.BonanzaPage,
    }
  },
  getPlaylistMachineName() {
    const playlistMachineName = Storage.get(STORAGE_KEYS.PLAYLIST_MACHINE_NAME)
    return playlistMachineName ? { playlistMachineName } : {}
  },
  showsPageVariables() {
    return {
      ...this.getCommonVariables(EntityPageType.Page, 'paginatedAllShows'),
      ...this.getCoastValues(nationalBroadcastType(), nbcAffiliateName()),
      ...this.getTelemundoAffiliateName(telemundoAffiliateName()),
      queryName: QueryNames.BonanzaPage,
    }
  },
  liveSchedulePageVariables() {
    const nbcName = nbcAffiliateName()
    return {
      ...this.getCommonVariables(EntityPageType.LiveSchedule, nbcName || 'wnbc'),
      ...this.getCoastValues(nationalBroadcastType(), nbcName),
      ...this.getTelemundoAffiliateName(telemundoAffiliateName()),
      queryName: QueryNames.BonanzaPage,
    }
  },
  streamPageVariables(name: string, callSign: string) {
    const nbcName = nbcAffiliateName()
    return {
      ...this.getCommonVariables(EntityPageType.Stream, name || nbcName || 'wnbc'),
      ...this.getCoastValues(nationalBroadcastType(), nbcName),
      ...this.getTelemundoAffiliateName(telemundoAffiliateName()),
      queryName: QueryNames.BonanzaPage,
      callSign,
    }
  },
  slePageVariables(pid: string | number) {
    return {
      ...this.getCommonVariables(EntityPageType.Stream, `${pid}`),
      ...this.getCoastValues(nationalBroadcastType(), nbcAffiliateName()),
      ...this.getTelemundoAffiliateName(telemundoAffiliateName()),
      queryName: QueryNames.BonanzaPage,
    }
  },
  showPageVariables(type: EntityPageType, urlalias: string) {
    const variables = {
      ...this.getCommonVariables(type, urlalias),
      userId: !this.getPersonalizationFlag() ? getMpid() : '0',
    }

    return {
      ...variables,
      queryName: QueryNames.BonanzaPage,
    }
  },
  networksPageVariables() {
    return {
      ...this.getCommonVariables(EntityPageType.Page, 'allBrands'),
      queryName: QueryNames.BonanzaPage,
    }
  },
  networkLandingPageVariables(machineName: string) {
    return {
      ...this.getCommonVariables(EntityPageType.BrandLanding, machineName),
      ...this.getCoastValues(nationalBroadcastType(), nbcAffiliateName()),
      ...this.getTelemundoAffiliateName(telemundoAffiliateName()),
      queryName: QueryNames.BonanzaPage,
    }
  },
  mixedEditorialShelfVariables(queryVariables: Record<string, any>) {
    return {
      // @ts-expect-error FIXME: We should pass an EntityPageType value
      ...this.getCommonVariables(),
      ...queryVariables,
      ...this.getCoastValues(nationalBroadcastType(), nbcAffiliateName()),
      ...this.getTelemundoAffiliateName(telemundoAffiliateName()),
      seoFriendlyName: '',
      queryName: QueryNames.MixedEditorialShelf,
    }
  },
  searchPageVariables() {
    return {
      ...this.getCommonVariables(EntityPageType.Page, ''),
      queryName: QueryNames.BonanzaPage,
    }
  },
  videoPageVariables(videoId: string) {
    return {
      ...this.getCommonVariables(EntityPageType.Video, videoId),
      ...this.getCoastValues(nationalBroadcastType(), nbcAffiliateName()),
      ...this.getTelemundoAffiliateName(telemundoAffiliateName()),
      ...this.getPlaylistMachineName(),
      queryName: QueryNames.FeaturedShowsSection,
    }
  },
  endCardVariables(type: VideoType, videoId: string) {
    return {
      ...this.getCommonVariables(EntityPageType.Page, ''),
      ...this.getPlaylistMachineName(),
      type,
      mpxGuid: `${videoId}`,
      queryName: QueryNames.EndCard,
    }
  },
  endCardSLEVariables(type: VideoType, videoId: number) {
    return {
      ...this.getCommonVariables(EntityPageType.Page, ''),
      ...this.getPlaylistMachineName(),
      type,
      pid: videoId,
      queryName: QueryNames.EndCard,
    }
  },
  userInteractionsVariables() {
    return {
      // @ts-expect-error FIXME: First argument should be an EntityPageType value
      ...this.getCommonVariables('', ''),
      queryName: QueryNames.UserInteractions,
    }
  },
  componentsForPlaceholdersVariables(variables: ComponentsForPlaceholdersQueryVariables) {
    return {
      queryName: QueryNames.ComponentsForPlaceholders,
      ...variables,
      userId: !this.getPersonalizationFlag() ? getMpid() : '0',
    }
  },
  getMergedVariables(variables: Record<string, any>): CommonVariables & Record<string, any> {
    return {
      // @ts-expect-error TS(2554): Expected 2 arguments, but got 0.
      ...this.getCommonVariables(),
      ...variables,
    }
  },
  getPersonalizationFlag() {
    console.log('LaunchDarkly getPersonalizationFlag', LaunchDarklySingleton.getAllFlag())
    return LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.removePersonalization)
  },
  getCommonVariables(type: EntityPageType, name: string): CommonVariables {
    const userId = !this.getPersonalizationFlag() ? getMpid() : '0'

    // Todo: make this dynamic, hardcoded for now
    return {
      app: getConfigName() as NbcuBrands,
      name,
      type,
      userId,
      platform: TvPlatform.bffPlatform,
      ld: true,
      timeZone: getTimezone(),
      oneApp: true,
      language: Storage.get(STORAGE_KEYS.APP_LANGUAGE) || LANGUAGES.DEFAULT,
      authorized: AuthenticationSingleton.isAuthenticated(),
      isDayZero: getIsDayZero(),
      appVersion: formatAppVersion(version),
    }
  },
  getGlobalNavigationVariables() {
    return {
      // @ts-expect-error TS(2554): Expected 2 arguments, but got 0.
      ...this.getCommonVariables(),
      queryName: QueryNames.GlobalNavigation,
    }
  },
  getCoastValues(
    nationalBroadcastType: string,
    nbcAffiliateName: string
  ): CoastVariables | Record<string, never> {
    return nationalBroadcastType && nbcAffiliateName
      ? { nationalBroadcastType, nbcAffiliateName }
      : {}
  },
  getTelemundoAffiliateName(
    telemundoAffiliateName: string
  ): TelemundoVariables | Record<string, never> {
    return telemundoAffiliateName ? { telemundoAffiliateName } : {}
  },
  getScrollFromSeasonVariables(
    season: string,
    urlAlias: string
  ): CommonVariables & Pick<ScrollFromSeasonQueryVariables, 'seasonNumber' | 'urlAlias'> {
    return {
      ...this.getCommonVariables(EntityPageType.TitleV2, urlAlias),
      seasonNumber: parseInt(season),
      urlAlias,
    }
  },
  olympicsVideoPageVariables(videoId: string) {
    return {
      ...this.getCommonVariables(EntityPageType.OlympicsVideo, videoId),
      ...this.getCoastValues(nationalBroadcastType(), nbcAffiliateName()),
      ...this.getTelemundoAffiliateName(telemundoAffiliateName()),
      ...this.getPlaylistMachineName(),
      queryName: QueryNames.FeaturedShowsSection,
    }
  },
}

export default Variables
