interface IKeyMap {
  [param: string | number]: string
}

interface IAdvertisingData {
  LMT: 1 | 0
  IFA: string
}

interface IVizioDeviceInfo {
  SYSTEM_INFO: {
    TVAD_ID: IAdvertisingData
    CHIPSET_NAME?: string
  }
}

interface IVizioLaunchApp {
  NAME_SPACE: number
  APP_ID: string
  MESSAGE: string
}

interface IVizoDeviceManifest {
  tts_enabled: boolean
}

interface IVizioChromevox {
  cancel(): void
  play(text: string): void
}

interface IVIZIO {
  getDeviceInformation(): Promise<IVizioDeviceInfo>

  setAdvertiserIDListener(callback: (data: IAdvertisingData) => void): void

  getDeviceId(callback: (deviceId: string) => void): void

  getFirmwareVersion(callback: (firmware: string) => void): void

  setClosedCaptionHandler(callback: (enabled: boolean) => void): void

  launchApplication(data: IVizioLaunchApp): void

  exitApplication(): void

  getDeviceManifest(): Promise<IVizoDeviceManifest>

  isSmartCastDevice: boolean
  deviceModel: string
  Chromevox?: IVizioChromevox
}

enum LEMONADE_PLATFORM {
  XCLASS = 'platco',
  XBOXONE = 'xboxOne',
  VIZIO = 'vizio',
  TIZEN = 'samsungTv',
  KEPLER = 'fireTvLightning',
}

enum APP_IDENTIFIER {
  XCLASS = 'nbcu-x1',
  XBOXONE = 'nbcu-xboxone',
  VIZIO = 'nbcu-vizio',
  TIZEN = 'nbcu-samsung',
  KEPLER = 'nbcu-firetv',
}

interface IContactData {
  contact: string
  address: string
}

interface IStageSettings {
  devicePixelRatio: number
  precision?: number
  enablePointer?: boolean
  readPixelsAfterDraw?: boolean
  readPixelsAfterDrawThreshold?: number
  memoryPressure?: number
  pauseRafLoopOnIdle?: boolean
}

enum ErrorType {
  NETWORK = 'network',
  MEDIA = 'media',
  RESTRICTION = 'restriction',
  ENTITLEMENT = 'entitlement',
  OTHER = 'other',
}

export {
  IKeyMap,
  IAdvertisingData,
  IContactData,
  IVizioDeviceInfo,
  IVIZIO,
  LEMONADE_PLATFORM,
  APP_IDENTIFIER,
  IStageSettings,
  ErrorType,
}
