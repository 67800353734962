import { Language } from '@lightningjs/sdk'

import BaseComponent from '../../base'
import FocusScaledButton from '../../buttons/FocusScaledButton'

import { LiveControlButtons } from './states/LiveControls'
import { FONT_FACE, COLORS } from '../../../constants'

export default class LiveControls extends BaseComponent {
  toggleBackToLive(showToggle: boolean) {
    this.tag(LiveControlButtons.BackToLiveButton).visible = showToggle
  }

  override _active() {
    this.tag(LiveControlButtons.LiveGuideButton).patch({
      label: Language.translate('view_live_guide'),
    })
    this.tag(LiveControlButtons.BackToLiveButton).patch({
      label: Language.translate('go_to_live'),
    })
  }

  static override _template() {
    return {
      x: 1228,
      y: 825,
      alpha: 1,
      [LiveControlButtons.BackToLiveButton]: {
        x: 0,
        label: Language.translate('go_to_live'),
        type: FocusScaledButton,
        unfocusHeight: 55,
        unfocusWidth: 228,
        focusHeight: 55,
        focusWidth: 228,
        radius: 27.5,
        fontSize: 32,
        focusFontSize: 32,
        fontFace: FONT_FACE.regular,
        focusFontColor: COLORS.white,
        unfocusFontColor: COLORS.lightGray4,
        focusBackGroundColor: COLORS.red,
        unfocusBackgroundColor: COLORS.transparent,
        focusBorderColor: COLORS.transparent,
        unfocusBorderColor: COLORS.lightGray4,
        visible: false,
      },
      [LiveControlButtons.LiveGuideButton]: {
        x: 248,
        label: Language.translate('view_live_guide'),
        type: FocusScaledButton,
        unfocusHeight: 55,
        focusHeight: 55,
        radius: 27.5,
        fontSize: 32,
        focusFontSize: 32,
        fontFace: FONT_FACE.regular,
        focusFontColor: COLORS.black,
        unfocusFontColor: COLORS.lightGray4,
        focusBackGroundColor: COLORS.white,
        unfocusBackgroundColor: COLORS.transparent,
        focusBorderColor: COLORS.transparent,
        unfocusBorderColor: COLORS.lightGray4,
        autoWidth: true,
        padding: 36,
      },
    }
  }
}
