import { Lightning, Router } from '@lightningjs/sdk'
import ModalManager, { ModalTypes } from '../../lib/ModalManager'
import QrModal from './qrModal'
import UpcomingModal from './upcomingModal'
import ErrorModal from './errorModal/ErrorModal'
import PlayerLoader from './playerLoader/PlayerLoader'
import Activation from './activation/Activation'
import TempPassActivation from './activation/TempPassActivation'
import TempPassConcluded from './activation/TempPassConcluded'

export const ModalTypeTagMap: Record<
  ModalTypes,
  { key: keyof Router.Widgets; component: Lightning.Component.Constructor }
> = {
  [ModalTypes.QR_MODAL]: { key: 'QrModal', component: QrModal },
  [ModalTypes.UPCOMING]: { key: 'UpcomingLiveModal', component: UpcomingModal },
  [ModalTypes.PLAYER_ERROR]: { key: 'PlayerErrorModal', component: ErrorModal },
  [ModalTypes.PLAYER_LOADER]: { key: 'PlayerLoaderModal', component: PlayerLoader },
  [ModalTypes.ACTIVATION]: { key: 'Activation', component: Activation },
  [ModalTypes.ACTIVATION_TEMPPASS_MVPD]: { key: 'ActivationTempPassMvpd', component: Activation },
  [ModalTypes.ACTIVATION_TEMPPASS_NBC]: {
    key: 'ActivationTempPassNbc',
    component: TempPassActivation,
  },
  [ModalTypes.TEMPPASS_CONCLUDED]: {
    key: 'TempPassConcluded',
    component: TempPassConcluded,
  },
}

export type ModalHandler = {
  destroy(): void
}

export const loadModals = () =>
  Object.values(ModalTypeTagMap).reduce(
    (acc, item, index) => ({
      ...acc,
      [item.key]: {
        type: item.component,
        visible: false,
        zIndex: 200 + index,
      },
    }),
    {}
  )

export const createModal = (ctx: any, type: ModalTypes, payload: any): ModalHandler => {
  const { key } = ModalTypeTagMap[type]
  const modal = ctx.tag(key)
  ;(modal.visible = true), (modal.modalData = payload)
  Router.focusWidget(key)

  return {
    destroy() {
      modal.visible = false
      Router.focusPage()
    },
  }
}

export const updateModal = (ctx: any, type: ModalTypes, payload: any) => {
  const { key } = ModalTypeTagMap[type]
  const modal = ctx.tag(key)
  modal.modalData = payload
}

export const getCurrentModalInstance = () => {
  const page = Router.getActivePage()
  const activeModal = ModalManager._activeModal && ModalTypeTagMap[ModalManager._activeModal]
  if (page && activeModal) return page.fireAncestors('$getWidget', activeModal.key)
  return null
}
