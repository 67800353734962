import { Language, Storage, Utils } from '@lightningjs/sdk'
import { Track } from '@sky-uk-ott/core-video-sdk-js-core'

import SelectButton from '../../buttons/SelectButton'
import CCSettingsButton from '../../buttons/CCSettingsButton'
import SettingsButton from '../../buttons/SettingsButton'

import {
  AudioLanguageTag,
  getAudioTrackLabel,
  getPreferredLanguages,
  mapAudioLanguageTag,
  setHeightOfChildrenItems,
  setSmooth,
} from '../../../helpers'
import { CCTypes, ClosedCaptionsUtils } from '../../../lib/ClosedCaptions/ClosedCaptionsUtils'
import Preferences from '../../../lib/Preferences'
import {
  COLORS,
  FLEX_DIRECTION,
  FONT_FACE,
  SCREEN_SIZE,
  STORAGE_KEYS,
  VERTICAL_ALIGN,
} from '../../../constants'
import { MainStateFactory } from './states/Main'
import { CaptionsSettingsStateFactory } from './states/CaptionsSettings'
import BaseComponent from '../../base'
import { getAudioTrackComponent, getLanguageListComponent } from './utils'

const DEFAULT_TRACKS_LIST = [
  {
    optionValue: CCTypes.en,
    kind: 'caption',
  },
]

export const CCSettingsItems = [
  Preferences.CLOSED_CAPTION_FONT_STYLE,
  Preferences.CLOSED_CAPTION_FONT_SIZE,
  Preferences.CLOSED_CAPTION_FONT_COLOR,
  Preferences.CLOSED_CAPTION_FONT_OPACITY,
  Preferences.CLOSED_CAPTION_BACKGROUND_OPACITY,
  Preferences.CLOSED_CAPTION_BACKGROUND_COLOR,
  Preferences.CLOSED_CAPTION_WINDOW_OPACITY,
  Preferences.CLOSED_CAPTION_WINDOW_COLOR,
  Preferences.CLOSED_CAPTION_EDGE_OPACITY,
  Preferences.CLOSED_CAPTION_EDGE_STYLE,
  Preferences.CLOSED_CAPTION_EDGE_COLOR,
]

export default class MediaOptionsPanel extends BaseComponent {
  _backButton: any
  _main: any
  _captionsAppearanceButton: any
  _captionsAppearanceContainer: any
  _captionsAppearanceList: any
  _captionsLanguageList: any
  _audioLanguageList: any
  _captionsSettingContainer: any
  _captionsSettingHeading: any
  _captionsSettingList: any
  _captionsSettingsContainer: any
  _languageListIndex: number
  _audioLanguageListIndex: number
  _resetButton: any
  _allowCCStyleChange = true
  _currentAudioTrackId!: string | number
  _languageTracks = DEFAULT_TRACKS_LIST

  static override _template() {
    return {
      x: SCREEN_SIZE.width,
      w: 680,
      h: SCREEN_SIZE.height,
      rect: true,
      color: COLORS.black2,
      clipping: true,
      Holder: {
        x: 90,
        y: 105,
        w: 500,
        h: SCREEN_SIZE.height - 90,
        Main: {
          h: (h: number) => h,
          flex: {
            direction: FLEX_DIRECTION.column,
          },
          CaptionsHeading: {
            x: 20,
            text: {
              textColor: COLORS.white2,
              verticalAlign: VERTICAL_ALIGN.middle,
              fontSize: 24,
              lineHeight: 36,
              fontFace: FONT_FACE.regular,
              maxLines: 1,
              wordWrapWidth: 460,
              text: Language.translate('captions').toUpperCase(),
            },
          },
          CaptionsLanguageList: {
            flexItem: {
              marginBottom: 60,
            },
          },
          AudioHeading: {
            x: 20,
            text: {
              textColor: COLORS.white2,
              verticalAlign: VERTICAL_ALIGN.middle,
              fontSize: 24,
              lineHeight: 36,
              fontFace: FONT_FACE.regular,
              maxLines: 1,
              wordWrapWidth: 460,
              text: 'AUDIO',
            },
          },
          AudioLanguageList: {
            flexItem: {
              marginBottom: 60,
            },
          },
          Divider1: {
            alpha: 0,
            w: 500,
            h: 3,
            rect: true,
            color: COLORS.mediumGray3,
          },
          CaptionsAppearanceButton: {
            alpha: 0,
            w: 500,
            h: 93,
            type: CCSettingsButton,
            radius: 0,
            fontSize: 30,
            fontFace: FONT_FACE.light,
            focusFontColor: COLORS.black,
            unfocusFontColor: COLORS.white2,
            focusBackGroundColor: COLORS.white,
            unfocusBackgroundColor: COLORS.transparent,
            label: Language.translate('captions_appearance'),
            padding: 0,
            icon: 'images/settings/line.png',
            description: Language.translate('customize_cc_settings'),
          },
        },
        CaptionsSettingsContainer: {
          alpha: 0,
          BackButton: {
            x: 26,
            y: 5,
            color: COLORS.mediumGray3,
            src: Utils.asset('images/back-arrow.png'),
          },
          CaptionsAppearanceContainer: {
            alpha: 0,
            y: 89,
            AppearanceHeading: {
              x: 20,
              text: {
                textColor: COLORS.white2,
                verticalAlign: VERTICAL_ALIGN.middle,
                fontSize: 24,
                lineHeight: 36,
                fontFace: FONT_FACE.regular,
                maxLines: '1',
                wordWrapWidth: 460,
                text: Language.translate('captions_appearance').toUpperCase(),
              },
            },
            ResetButton: {
              y: 57,
              w: 500,
              h: 60,
              type: SettingsButton,
              radius: 0,
              fontSize: 30,
              fontFace: FONT_FACE.light,
              focusFontColor: COLORS.black,
              unfocusFontColor: COLORS.white2,
              focusBackGroundColor: COLORS.white,
              unfocusBackgroundColor: COLORS.transparent,
              label: Language.translate('reset'),
              padding: 0,
            },
            Divider2: {
              y: 147,
              w: 500,
              h: 3,
              rect: true,
              color: COLORS.mediumGray3,
            },
            CaptionsAppearanceListContainer: {
              y: 184,
              w: 500,
              h: 730,
              clipping: true,
              CaptionsAppearanceList: {},
            },
          },
          CaptionsSettingContainer: {
            alpha: 0,
            y: 89,
            CaptionsSettingHeading: {
              x: 20,
              text: {
                textColor: COLORS.white2,
                verticalAlign: VERTICAL_ALIGN.middle,
                fontSize: 24,
                lineHeight: 36,
                fontFace: FONT_FACE.regular,
                maxLines: '1',
                wordWrapWidth: 460,
              },
            },
            Divider3: {
              y: 57,
              w: 500,
              h: 3,
              rect: true,
              color: COLORS.mediumGray3,
            },
            CaptionsSettingList: {
              y: 84,
            },
          },
        },
      },
    }
  }

  set currentAudioTrackId(trackId: string | number) {
    this._currentAudioTrackId = trackId
    this._audioLanguageList.children.forEach((child: any) => {
      child.selected = child.optionValue.id === trackId
    })
  }

  _reset() {
    this._languageListIndex = 0
    this._audioLanguageListIndex = 0
    this._main.alpha = 1
    this._captionsSettingsContainer.alpha = 0
    this._captionsAppearanceContainer.alpha = 0
    this._captionsSettingContainer.alpha = 0
    this._setState('Main.CCLanguagesList')
  }

  override _init() {
    this._main = this.tag('Main')
    this._captionsLanguageList = this.tag('CaptionsLanguageList')
    this._audioLanguageList = this.tag('AudioLanguageList')
    this._captionsAppearanceButton = this.tag('CaptionsAppearanceButton')
    this._captionsSettingsContainer = this.tag('CaptionsSettingsContainer')
    this._backButton = this.tag('BackButton')
    this._captionsAppearanceContainer = this.tag('CaptionsAppearanceContainer')
    this._resetButton = this.tag('ResetButton')
    this._captionsAppearanceList = this.tag('CaptionsAppearanceList')
    this._captionsSettingContainer = this.tag('CaptionsSettingContainer')
    this._captionsSettingHeading = this.tag('CaptionsSettingHeading')
    this._captionsSettingList = this.tag('CaptionsSettingList')
    this._languageTracks = DEFAULT_TRACKS_LIST
    this._createLanguageList()
    this._createAppearanceList()
    this._setState('Main.CCLanguagesList')
    this._languageListIndex = 0
    this._audioLanguageListIndex = 0
    if (this._allowCCStyleChange) {
      this.tag('Divider1').alpha = 1
      this._captionsAppearanceButton.alpha = 1
    }
  }

  _showMediaOptionsMenu(v: any) {
    setSmooth(this, 'x', v ? 1280 : SCREEN_SIZE.width)
  }

  _handleBackButton() {
    return false
  }

  _createLanguageList(tracks?: { optionValue: CCTypes; kind: string }[]) {
    if (tracks && tracks.length > 0)
      this._languageTracks = tracks.filter((track: { optionValue: CCTypes; kind: string }) => {
        return track.kind === 'caption'
      })

    const selectedCCType = ClosedCaptionsUtils.getCCType()

    const selectedType =
      this._languageTracks.find((track) => track.optionValue === selectedCCType)?.optionValue ||
      this._languageTracks.find(
        (track) => track.optionValue === ClosedCaptionsUtils.getFallbackCCType(selectedCCType)
      )?.optionValue ||
      CCTypes.off

    this._captionsLanguageList.patch({
      children: this._languageTracks.reduce(
        (acc, { optionValue }) =>
          !CCTypes[optionValue]
            ? acc
            : acc.concat(getLanguageListComponent(optionValue, acc.length, selectedType)),
        [getLanguageListComponent(CCTypes.off, 0, selectedType)]
      ),
    })

    setHeightOfChildrenItems(this._captionsLanguageList)
  }

  _createAudioLanguageList(tracks: Track[]) {
    if (tracks?.length) {
      this._audioLanguageList.patch({
        children: tracks
          .filter((track) => {
            const audioLanguageTag = track.languageTag || track.language
            return (
              track.label !== 'und' &&
              audioLanguageTag &&
              getAudioTrackLabel(mapAudioLanguageTag(audioLanguageTag as AudioLanguageTag)) !==
                undefined
            )
          })
          .map((track, index: number, tracks) => getAudioTrackComponent(track, index, tracks)),
      })

      setHeightOfChildrenItems(this._audioLanguageList)
      if (this._audioLanguageList.children.length) this.tag('AudioHeading').visible = true
      this._setPreferredAudioTrack()
    }
  }

  hideAudioSection() {
    this.tag('AudioHeading').visible = false
  }

  _createAppearanceList() {
    const children = CCSettingsItems.map((ccSettingItem, index) => ({
      y: index * (93 + 12),
      w: 500,
      h: 93,
      type: CCSettingsButton,
      radius: 0,
      fontSize: 30,
      fontFace: FONT_FACE.light,
      focusFontColor: COLORS.black,
      unfocusFontColor: COLORS.white2,
      focusBackGroundColor: COLORS.white,
      unfocusBackgroundColor: COLORS.transparent,
      label: ClosedCaptionsUtils.getLabel(ccSettingItem),
      padding: 0,
      icon: 'images/settings/line.png',
      description: ClosedCaptionsUtils.getDisplayLabelForOption(ccSettingItem),
    }))

    this._captionsAppearanceList.patch({ children })
  }

  _updateAppearanceButtons() {
    CCSettingsItems.forEach((ccSettingItem, index) => {
      this._captionsAppearanceList.children[index].description =
        ClosedCaptionsUtils.getDisplayLabelForOption(ccSettingItem)
    })
  }

  _updateAppearanceButtonValue(ccSettingItem: any) {
    const index = CCSettingsItems.indexOf(ccSettingItem)
    this._captionsAppearanceList.children[index].description =
      ClosedCaptionsUtils.getDisplayLabelForOption(ccSettingItem)
  }

  _updateAudioLanguage = ({ id, languageTag }: Track, save = true): void => {
    if (id !== this._currentAudioTrackId) {
      if (save)
        Storage.set(
          STORAGE_KEYS.PREFERRED_AUDIO_LANG,
          mapAudioLanguageTag(languageTag as AudioLanguageTag)
        )
      this.fireAncestors('$audioLanguageUpdated', id)
    }
  }

  _setPreferredAudioTrack = (): void => {
    const preferredLanguages = getPreferredLanguages()
    for (const language of preferredLanguages) {
      if (
        this._audioLanguageList.children.some((element: SelectButton) => {
          const result = element.optionValue.languageTag === language
          element.selected = result
          if (result) this._updateAudioLanguage(element.optionValue, false)
          return result
        })
      )
        break
    }
  }

  static override _states(): any[] {
    return [MainStateFactory(this), CaptionsSettingsStateFactory(this)]
  }
}
