import Announce from './Announce'
import { Registry } from '@lightningjs/sdk'

const estimateSpeechTime = (text: string, wordsPerMinute = 160):number => {
  return Math.ceil(text.trim().split(/\s+/).length / wordsPerMinute * 60) * 1000;
}

export default class VizioAnnounce extends Announce {
  async speak(): Promise<void> {
    return new Promise<void>((resolve) => {
      window.VIZIO.Chromevox?.play(this.phrase)
      Registry.setTimeout(() => resolve(), estimateSpeechTime(this.phrase))
    })
  }
}
