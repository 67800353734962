import { Lightning, Settings, Utils } from '@lightningjs/sdk'

import { getMvpdLogo, checkGetMvpd } from './auth'
import {
  CLIP_PROGRAMMING_TYPES,
  COLORS,
  FONT_FACE,
  PROGRAMMING_TYPES,
  svgRegExp,
  UNAUNTHENTICATED,
  VERTICAL_ALIGN,
} from '../constants'
import { cloneDeep } from 'lodash'
import { IMAGE_RESIZE_MODE, OLY_IMPOLICY } from '../constants'
import MvpdData from '../api/models/MvpdData'

export const DEFAULT_TIME_LIMIT_UPCOMING_ITEM = 600000
export const world = (el: any) => {
  const { px: x, py: y } = el.core.renderContext
  return { x, y, w: el.finalW }
}

export const setSmooth = (
  child: Lightning.Element | undefined,
  p: string,
  v: any,
  settings = {}
) => {
  if (!child) return
  if (!Settings.get('platform', 'transitions')) {
    const anyChild = child as any
    anyChild[`${p}`] = v
  } else {
    child.setSmooth(p, v, settings)
  }
}

export const updateElementH = (elem: any) => {
  let yOffset = 0
  for (const ch of elem.children) {
    ch.y = yOffset
    yOffset += ch.h
  }
  elem.h = elem.children.reduce((acc: any, ch: any) => acc + ch.h, 0)
}

type TextCommonStyles = {
  w?: number
  fontFace?: FONT_FACE
  fontSize?: number
  verticalAlign?: VERTICAL_ALIGN
}
type TextType = TextCommonStyles & {
  text: string
}
const isTextType = (item: string | TextType): item is TextType => typeof item !== 'string'
export const createFlexText = (text: (string | TextType)[], common?: TextCommonStyles) => {
  return text.map((t) => {
    const isObject = isTextType(t)
    const verticalAlign = (isObject && t.verticalAlign) || common?.verticalAlign
    return {
      w: (isObject && t.w) || common?.w || 0,
      flexItem: { marginRight: 10 },
      text: {
        text: isObject ? t.text : t,
        fontFace: (isObject && t.fontFace) || common?.fontFace || FONT_FACE.light,
        fontSize: (isObject && t.fontSize) || common?.fontSize || 40,
      },
      ...(verticalAlign ? { verticalAlign } : {}),
    }
  })
}

export const checkSetMvpdLogo = (context: any) =>
  checkGetMvpd().then((mvpd) => {
    if (mvpd === UNAUNTHENTICATED) hideMvpdLogo(context)
    if (typeof mvpd === 'object') setMvpdLogo(context, mvpd as any)
  })

export const checkAddClipToVideoTitles = (meta: any, titlesPatchData: any) => {
  if (isAClip(meta?.programmingType)) {
    titlesPatchData.TitleContainer.Clip = {
      Icon: {
        x: 143,
        flexItem: { marginRight: 8, marginTop: 3 },
        texture: Lightning.Tools.getRoundRect(
          54,
          26,
          0,
          1,
          COLORS.mediumGray4,
          true,
          COLORS.transparent
        ),
        Label: {
          mount: 0.5,
          x: 29,
          y: 16,
          text: { text: 'CLIP', fontFace: FONT_FACE.light, fontSize: 24 },
        },
      },
    }
    titlesPatchData.TitleContainer.SecondaryTitle.x = 209
    titlesPatchData.TitleContainer.SecondaryTitle.y = 77
  }
  return titlesPatchData
}

// Using the context ('this' from component), animate component with given tagname (default is 'MvpdLogo')
export const animateMvpdLogo = (context: any, show = true, tagName = 'MvpdLogo') => {
  context.tag(tagName).setSmooth('alpha', +show, {
    duration: 0.9,
    timingFunction: 'cubic-bezier(0.20, 1.00, 0.80, 1.00)',
  })
}

export const setMvpdLogo = (context: any, mvpd: MvpdData | null = null, tagName = 'MvpdLogo') => {
  if (!mvpd) return
  // Update the image path.
  context.tag(tagName).patch({
    // Build full mvpd logo path.
    src: getMvpdLogo(mvpd),
  })
  // Animate the logo in.
  animateMvpdLogo(context, true, tagName)
}

export const hideMvpdLogo = (context: any, tagName = 'MvpdLogo') => {
  context.tag(tagName).patch({
    alpha: 0,
    src: undefined,
  })
}

/**
 * Checks if a video item is a clip.
 * @return {boolean} Is a clip
 * @param programmingType
 */
export const isAClip = (programmingType: PROGRAMMING_TYPES): boolean => {
  return CLIP_PROGRAMMING_TYPES.has(programmingType)
}

export const sponsorBadgeText = (text: string) => {
  return {
    text,
    textColor: COLORS.mediumGray,
    fontSize: 32,
    maxLines: 1,
    wordWrapWidth: 600,
    fontFace: FONT_FACE.light,
  }
}

const isObject = (value: any) => {
  return value !== null && typeof value === 'object'
}

const merge = (objA: any, objB: any) => {
  const aKeys = Object.keys(objA)
  const bKeys = Object.keys(objB).filter((key) => !aKeys.includes(key))

  aKeys.forEach((key) => {
    const a = objA[key]
    const b = objB[key]
    if (key in objB && b === undefined) {
      delete objA[key]
    } else if (isObject(a) && isObject(b)) {
      objA[key] = merge(a, b)
    } else {
      objA[key] = b ?? a
    }
  })
  bKeys.forEach((key) => {
    objA[key] = objB[key]
  })
  return objA
}

export function templateDeepMerge(original: any, override: any) {
  if (original === override || !isObject(override)) {
    return original
  }
  return merge(original, override)
}

export const getSvgTexture = (imgUrl: any, { w, h }: any) => {
  const url = Utils.proxyUrl(imgUrl)
  return Lightning.Tools.getCanvasTexture((cb, stage) => {
    const canvas = stage.platform.getDrawingCanvas()
    const ctx = canvas?.getContext('2d')
    if (ctx) ctx.imageSmoothingEnabled = true
    const img = new Image()
    img.onload = () => {
      const aspectRatio = img.naturalWidth / img.naturalHeight
      const sizesKnown = w && h
      if ((!w && h) || (sizesKnown && w < h)) {
        canvas.width = h * aspectRatio
        canvas.height = h
      } else if ((!h && w) || (sizesKnown && w > h)) {
        canvas.width = w
        canvas.height = w / aspectRatio
      } else if (sizesKnown) {
        canvas.width = w
        canvas.height = h
      } else {
        canvas.width = img.naturalWidth
        canvas.height = img.naturalHeight
      }
      if (ctx) ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
      cb(null, canvas)
    }
    img.crossOrigin = 'Anonymous'
    img.src = url
  }, `svg${url}`)
}

export const FastImg = (url: any) => {
  const make = (mode: IMAGE_RESIZE_MODE, width: number, height: number, impolicy: OLY_IMPOLICY) => {
    if (!url) return {}
    const isNbcImage = url?.includes?.('nbc.com/')
    const isOlympicsImage = url?.includes?.('nbcolympics.com/')
    const isLocal = !/^(?:https?:)?\/\//i.test(url) && !url?.includes?.('data:image/png;base64')

    if (isLocal) {
      url = Utils.asset(url)
    } else if (isNbcImage || isOlympicsImage) {
      const urlWithoutQueryParams = url.split('?')[0]
      const widthHeightRegex = /([0-9]+)x([0-9]+)/
      const widthHeight = url.match(widthHeightRegex)
      const impolicyFallbackWidths = [60, 380, 420]

      let defaultTransformedUrl = urlWithoutQueryParams
      if (isOlympicsImage && impolicy != OLY_IMPOLICY.NONE)
        defaultTransformedUrl += `?impolicy=${impolicy}`
      else if (isNbcImage)
        defaultTransformedUrl += `?impolicy=nbc_com&imwidth=${width}&imheight=${height}`

      if (widthHeight) {
        const originalWidth = Number(widthHeight[1])
        const originalHeight = Number(widthHeight[2])
        const isOriginalBigger = originalHeight > height && originalWidth > width

        if (isOriginalBigger) {
          const adjustedWidth = (height * originalWidth) / originalHeight
          const adjustByHeight = adjustedWidth > width
          url =
            width === adjustedWidth
              ? `${urlWithoutQueryParams}?impolicy=nbc_com&im=Resize,${
                  adjustByHeight ? 'height' : 'width'
                }=${adjustByHeight ? height : width};Crop,width=${width},height=${height}`
              : defaultTransformedUrl
        }
      } else if (impolicyFallbackWidths.indexOf(width) > -1 || isOlympicsImage) {
        // if the resolution width matches one of the widths we look to fallback on,
        // ensure that we use the impolicy instead of bringing in a larger image than required
        url = defaultTransformedUrl
      }
    }
    if (svgRegExp.test(url)) {
      return getSvgTexture(url, { w: width, h: height })
    }
    return {
      type: Lightning.textures.ImageTexture,
      src: url,
      resizeMode: {
        type: mode,
        w: width,
        h: height,
      },
    }
  }

  return {
    cover: (width: number, height: number, impolicy: OLY_IMPOLICY = OLY_IMPOLICY.NONE) =>
      make(IMAGE_RESIZE_MODE.COVER, width, height, impolicy),
    contain: (width: number, height: number, impolicy: OLY_IMPOLICY = OLY_IMPOLICY.NONE) =>
      make(IMAGE_RESIZE_MODE.CONTAIN, width, height, impolicy),
    portrait: (height: number, impolicy: OLY_IMPOLICY = OLY_IMPOLICY.NONE) =>
      make(IMAGE_RESIZE_MODE.CONTAIN, height, height, impolicy),
    landscape: (width: number, impolicy: OLY_IMPOLICY = OLY_IMPOLICY.NONE) =>
      make(IMAGE_RESIZE_MODE.CONTAIN, width, width, impolicy),
  }
}

/**
 * @typedef {Object} StateMap - An array of states to check, in order of priority
 * @property {string} state - State name
 * @property {function(): boolean} if - Are we allowed to go into this state?
 */

/**
 * Will traverse the array of items and switch to the first state that meets the requirements
 * @param {*} ctx Class context
 * @param {(StateMap | string)[]} states Array of states
 */
export const switchState = (ctx: any, states: any) => {
  if (!ctx || !states.length) {
    return
  }
  for (let i = 0, n = states.length; i < n; i++) {
    const item = states[i]
    const isString = typeof item === 'string'
    const comparisonFunc = isString
      ? () => {
          const component = ctx.tag(item)
          // It doesn't make sense to change the state to another one in which there is no element to focus on, or it is hidden.
          return component && component.visible && component.alpha > 0.1
        }
      : item.if
    const state = isString ? item : item.state
    if (typeof comparisonFunc === 'function' && comparisonFunc()) {
      ctx._setState(state)
      break
    }
  }
}

export const convertHexToRgb = (hexColor: any) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hexColor.replace(shorthandRegex, (m: any, r: any, g: any, b: any) => r + r + g + g + b + b)

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor)
  // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null
}

const parseMarkdown = (
  separator: any,
  text: any,
  regularObject: any,
  boldObject: any,
  baseObject: any
) => {
  let piece = 0
  let nextVal = ''
  let i = 0
  let isClosing = true

  while (i < text.length) {
    // Current text index matches separator[0]
    if (text[i] === separator[0]) {
      let lengthRemaining = separator.length - 1
      let currentLength = 1
      let similarSplit = text[i]
      while (lengthRemaining) {
        if (text[i + currentLength] === separator[currentLength]) {
          similarSplit += text[i + currentLength]
          currentLength++
          lengthRemaining--
        } else {
          break
        }
      }
      if (!lengthRemaining) {
        if (nextVal) {
          const component = cloneDeep(isClosing ? regularObject : boldObject)
          component.text.text = nextVal
          baseObject[`Text${piece}`] = component
          piece++
          nextVal = ''
        }
        isClosing = !isClosing
      } else {
        nextVal += similarSplit
      }
      i += currentLength
    } else {
      nextVal += text[i]
      i++
    }
  }

  return baseObject
}

export const parseMarkdownBold = (
  text: any,
  regularObject: any,
  boldObject: any,
  baseObject: any
) => parseMarkdown('**', text, regularObject, boldObject, baseObject)

export const getHexColor = (hex: string): number => {
  if (!hex) {
    return 0x00
  }

  hex = hex.replace('#', '')
  const number = Number(`0xff${hex}`)
  return parseInt(`${number}`, 10)
}

export const fadeComponent = (
  component: Lightning.Component,
  endValue: number,
  options?: { duration: number }
) => {
  component
    .animation({
      duration: options?.duration ?? 0.5,
      repeat: 0,
      stopMethod: 'immediate',
      actions: [{ p: 'alpha', v: { 0: Number(!endValue), 1: endValue } }],
    })
    .start()
}

export const getFirstNonNull = (obj: Record<string, any>, keys: string[]) => {
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    if (key && ![undefined, null].includes(obj[key])) return obj[key]
  }
  return undefined
}

export const getAsString = (input: any, prefix?: string): string =>
  input ? (prefix ? prefix + input : input) : ''
