import { Language, Lightning, Log } from '@lightningjs/sdk'
import { DebugControllerSingleton } from '../../../util/debug/DebugController'

import Item from '../Item'
import {
  clipDataDecorator,
  durationBadgeDecorator,
  labelBadgeDecorator,
  lockDecorator,
  movieDataDecorator,
  progressDecorator,
  sfvodDataDecorator,
  sfvodShowHomeDataDecorator,
} from '../decorators'
import RouterUtil from '../../../util/RouterUtil'

import { FastImg, setSmooth } from '../../../helpers'
import {
  PROGRAMMING_TYPES,
  ENTITY_TYPES,
  MAX_LINES_SUFFIX,
  COLORS,
  FONT_FACE,
  FLEX_DIRECTION,
  ROUTE,
  ROUTE_ENTITY,
  OLY_IMPOLICY,
} from '../../../constants'
import UserInteractionsStoreSingleton from '../../../store/UserInteractions'
import TVPlatform from '../../../lib/tv-platform'
import { MeasurableTextStyle, measureTextHeight } from '../../../helpers/measure-text'

export default class VideoTile extends Item {
  percentViewed = 0

  override _init() {
    super._init()
    this.containerHeight =
      this.isShowHomePage && this._item?.programmingType === PROGRAMMING_TYPES.SFVOD ? 480 : 380

    this._addDecorators([
      progressDecorator(235, true),
      movieDataDecorator,
      clipDataDecorator,
      this.isShowHomePage ? sfvodShowHomeDataDecorator : sfvodDataDecorator,
      labelBadgeDecorator(this._item?.labelBadge),
      durationBadgeDecorator,
      lockDecorator,
    ])
  }

  static override _template() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2340: Only public and protected methods of the base class are accessible via the 'super' keyword.
    const duration = super.duration
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2340: Only public and protected methods of the base class are accessible via the 'super' keyword.
    const timingFunction = super.timingFunction
    const template = super._template()

    return {
      ...template,
      ShadowBlur: {
        mount: 0.5,
        x: 210,
        y: 120,
        w: 520,
        h: 300,
        alpha: 0.8,
        type: Lightning.components.FastBlurComponent,
        amount: 1,
        content: {
          Shadow: {
            color: COLORS.black,
            mount: 0.5,
            x: 260,
            y: 150,
            texture: Lightning.Tools.getRoundRect(420, 235, 0),
          },
        },
        transitions: {
          y: { duration, timingFunction },
          scale: { duration, timingFunction },
          alpha: { duration, timingFunction },
          amount: { duration, timingFunction },
        },
      },
      ImageHolder: {
        rect: true,
        colorLeft: COLORS.brightGrey,
        colorRight: COLORS.thunder2,
        Image: {
          pivotX: 0,
          transitions: {
            scale: { duration, timingFunction },
          },
        },
        WatchedOverly: {
          alpha: 0,
          color: COLORS.black7,
          rect: true,
          w: 420,
          h: 235,
          Watched: {
            x: (w: any) => w / 2,
            y: (h: any) => h / 2,
            mount: 0.5,
            alpha: 0.7,
            text: {
              fontFace: FONT_FACE.regular,
              fontSize: 50,
              wordWrapWidth: 420,
              maxLines: 1,
              textColor: COLORS.white,
              text: '',
            },
          },
        },
      },
      Content: {
        y: 250,
        TitleCard: {
          flex: { direction: FLEX_DIRECTION.column },
          ShortTitleContainer: {
            flex: { direction: FLEX_DIRECTION.row },
            ShortTitle: {
              text: { ...VideoTile.shortTitleStyle },
            },
          },
          Title: {
            flexItem: { marginTop: -8 },
            text: {
              fontFace: FONT_FACE.light,
              fontSize: 24,
              lineHeight: 30,
              wordWrapWidth: 360,
              maxLines: 2,
              maxLinesSuffix: MAX_LINES_SUFFIX,
            },
          },
          transitions: {
            y: { duration, timingFunction },
          },
        },
        Logo: {
          y: 4,
          mountX: 1,
          x: 420,
          transitions: {
            y: { duration, timingFunction },
          },
        },
      },
    }
  }

  get isShowHomePage() {
    return RouterUtil.isShowHomePage()
  }

  override get announce() {
    return this._item
      ? `${this._item?.title}, ${this._item?.secondaryTitle} press OK to select`
      : ''
  }

  override set announce(announce: string | string[]) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2340: Only public and protected methods of the base class are accessible via the 'super' keyword.
    super.announce = announce
  }

  set item(v: any) {
    this._item = v
    this.percentViewed = UserInteractionsStoreSingleton.getPercentViewed(this._item.v4ID)
    const isWatched = this.percentViewed >= 0.95
    const isOlympics = v.data.resourceId === 'nbcolympics' || v?.data?.isOlympics
    const mainTitle = this._getMainTitle(v, this.isShowHomePage)
    const mainTitleHeight = measureTextHeight(
      this.stage,
      mainTitle,
      VideoTile.shortTitleStyle,
      VideoTile.width
    )

    this.patch({
      ImageHolder: {
        w: 420,
        h: 235,
        Image: { texture: FastImg(v.image).contain(420, 235, OLY_IMPOLICY.RECT_SMEDIUM_640_360) },
        WatchedOverly: {
          alpha: isWatched,
          Watched: {
            text: Language.translate('watched'),
          },
        },
      },
      Content: {
        TitleCard: {
          ShortTitleContainer: {
            ShortTitle: {
              text: { text: mainTitle },
            },
          },
          Title: {
            text: {
              text: this._getSubTitle(v, this.isShowHomePage),
              maxLines: mainTitleHeight > 60 ? 1 : 2,
            },
          },
        },
        ...(!isOlympics
          ? {
              Logo: {
                texture: FastImg(v.logo).contain(60, 40, OLY_IMPOLICY.RECT_SMALL_384_216),
              },
            }
          : {}),
      },
    })
  }

  _getMainTitle(v: any, isShowHomePage?: any) {
    const programmingType = v.programmingType || v.obj.data.programType

    switch (programmingType) {
      case PROGRAMMING_TYPES.FULL_EPISODE:
        if (isShowHomePage) {
          return v.treatments?.includes('smartTile') ? v.secondaryTitle : v.showMetadata
        }
        return v.shortTitle
      case PROGRAMMING_TYPES.MOVIE:
      case PROGRAMMING_TYPES.SFVOD:
        return v.title
      case PROGRAMMING_TYPES.SLE:
      case PROGRAMMING_TYPES.FER:
        return v.title
      default:
        return isShowHomePage ? '' : v.shortTitle
    }
  }

  _getSubTitle(v: any, isShowHomePage: any) {
    const programmingType = v.programmingType || v.obj.data.programType
    switch (programmingType) {
      case PROGRAMMING_TYPES.MOVIE:
        return ''
      case PROGRAMMING_TYPES.FULL_EPISODE:
        if (isShowHomePage) {
          return v.treatments?.includes('smartTile') ? v.title : v.secondaryTitle
        }
        return v.showMetadata
      case PROGRAMMING_TYPES.SFVOD:
        return v.secondaryTitle
      case PROGRAMMING_TYPES.SLE:
      case PROGRAMMING_TYPES.FER:
        return v.secondaryTitle
      default:
        return v.title
    }
  }

  override _handleEnter(): void {
    Log.info('VideoTileItem', this._item)
    this.trackContentPosition()
    this.trackContentClick(ENTITY_TYPES.VIDEO)
    DebugControllerSingleton.streamUrl = this._item.HARDCODEDSTREAM
    RouterUtil.navigateToRoute(
      ROUTE.video,
      {
        entity: ROUTE_ENTITY.videoId,
        value: this._item.mpxGuid,
      },
      { isOlympicsVideo: this._item?.obj?.data?.isOlympics }
    )
  }

  override _inactive() {
    if (TVPlatform.getForceGC()) this.stage.gc()
  }

  override _focusAnimation(v: boolean) {
    super._focusAnimation(v)
    setSmooth(this.tag('Watched'), 'alpha', v ? 1 : 0.7, {
      duration: 0.3,
      delay: 0,
    })
    if (this.isShowHomePage && this._item.programmingType === PROGRAMMING_TYPES.SFVOD) {
      this.tag('SmartTimeStamp.ShortTitle').visible = v
    }
  }

  get isLocked() {
    return this._item?.isLocked
  }

  get lockContainer() {
    return this.tag('Image')
  }

  get keyBadgePosition() {
    return this.containerWidth
  }

  static get width() {
    return 420
  }

  static get shortTitleStyle(): MeasurableTextStyle {
    return {
      fontFace: FONT_FACE.semiBold,
      fontSize: 26,
      lineHeight: 32,
      maxLines: 3,
      wordWrapWidth: 360,
    }
  }

  static override get tag() {
    return 'VideoTile'
  }
}
