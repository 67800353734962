import Announce from './Announces/Announce'
import TVPlatform from '../tv-platform'
import { AnnouncementType } from './types'

class SpeechController {
  private _active = false
  private _announces: Announce[] = []
  private _tts = TVPlatform.tts()

  get active(): boolean {
    return this._active
  }

  append(toSpeak: AnnouncementType): void {
    this._announces.push(this._tts.speak(toSpeak))
  }

  cancel(): void {
    this._announces = []
    this._tts.cancel()
    this._active = false
  }

  async speak(series: AnnouncementType): Promise<void> {
    this._active = true
    this._announces.push(this._tts.speak(series))
    await this._speakSeries()
  }

  private async _speakSeries(): Promise<void> {
    while (this._announces.length) {
      const series = this._announces.shift()
      if (!series) continue
      await series.speak()
    }
    this._active = false
  }
}

export default new SpeechController()
