import { Language, Lightning } from '@lightningjs/sdk'
import {
  ALIGN_ITEMS,
  COLORS,
  FLEX_DIRECTION,
  FONT_FACE,
  JUSTIFY_CONTENT,
  TEXT_ALIGN,
  VERTICAL_ALIGN,
} from '../../../../constants'
import { FastImg } from '../../../../helpers'
import Item from '../../../items/Item'
import PlayerStoreSingleton from '../../../../store/PlayerStore/PlayerStore'
import { OLY_IMPOLICY } from '../../../../constants/images'

export default class GuideStream extends Item {
  _currentChannel = false
  _isFocused: boolean
  _isNbcNews: boolean
  override useFocusRing = false

  static override _template() {
    const template = super._template()
    return {
      ...template,
      w: 154,
      h: (h: number) => h,
      rect: true,
      color: COLORS.mediumGray6,
      shader: {
        type: Lightning.shaders.RoundedRectangle,
        radius: 6.375,
      },
      flex: {
        direction: FLEX_DIRECTION.column,
        alignItems: ALIGN_ITEMS.center,
        justifyContent: JUSTIFY_CONTENT.center,
      },
      BrandLogo: {
        color: COLORS.white,
        texture: {},
      },
      BrandName: {
        flexItem: {
          marginTop: 8,
        },
        visible: false,
        text: {
          fontFace: FONT_FACE.light,
          fontSize: 30,
          textAlign: TEXT_ALIGN.center,
        },
      },
      SecondaryTitle: {
        rect: true,
        color: COLORS.transparent,
        visible: false,
        flex: {
          direction: FLEX_DIRECTION.row,
          justifyContent: JUSTIFY_CONTENT.center,
        },
        flexItem: {
          marginTop: 8,
        },
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 4,
        },
        Text: {
          text: {
            fontSize: 20,
            textAlign: TEXT_ALIGN.center,
            textColor: COLORS.white,
            letterSpacing: 0.2,
            fontWeight: 400,
            lineHeight: 26,
            verticalAlign: VERTICAL_ALIGN.middle,
          },
        },
      },
    }
  }

  set item(item: any) {
    const { stream }: any = PlayerStoreSingleton.state
    this._currentChannel =
      stream?.callSign === item.callSign || stream?.streamAccessName === item.callSign

    if (this._currentChannel) {
      this.patch({
        Border: {
          flexItem: false,
          texture: Lightning.Tools.getRoundRect(
            1,
            182,
            [0.6375, 0, 0, 0.6375],
            7,
            COLORS.red,
            false
          ),
        },
      })
    }

    if (item.whiteBrandLogo) {
      this.tag('BrandLogo').patch({
        texture: FastImg(item.whiteBrandLogo).contain(94, 60, OLY_IMPOLICY.RECT_SMALL_384_216),
      })
    } else {
      this.tag('BrandName').patch({
        visible: true,
        text: {
          text: item.brandDisplayTitle,
        },
      })
    }

    // NBC-Now needs to have a secondary Title of FREE and a unique highlight
    this._isNbcNews = this.isNbcNow(item.channelId)

    if (this._isNbcNews || item.secondaryTitle) {
      this.tag('SecondaryTitle').patch({
        visible: true,
        Text: {
          text: {
            text: this._isNbcNews
              ? Language.translate('livePlayer-streamBadge-free')
              : item.secondaryTitle.toUpperCase(),
          },
        },
      })
    }

    this.tag('SecondaryTitle.Text').loadTexture()
    this.tag('SecondaryTitle').patch({
      w: this.tag('Text').renderWidth + 12,
    })
  }

  set focused(focused: any) {
    this._isFocused = focused

    this.patch({
      color: focused ? COLORS.lightGray1 : COLORS.mediumGray6,
    })

    this.tag('BrandLogo').patch({
      color: focused ? COLORS.black : COLORS.white,
    })

    this.tag('SecondaryTitle.Text').patch({
      text: {
        textColor: focused ? COLORS.black : COLORS.white,
      },
    })
  }

  isNbcNow(channelId = '') {
    return channelId === 'nbcnews'
  }
}
