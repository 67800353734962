import { PlatformSubscriptionType } from './tv-platform/base'
import { AppLaunchTypes } from './analytics/types'
import { getAppLaunchType, setAppLaunchType } from '../helpers'
import { Subscription } from 'rxjs'
import { SubscriptionBuilder, SubscriptionSources } from '../util/SubscriptionBuilder'
import { Router } from '@lightningjs/sdk'
import { ROUTE } from '../constants'
import { isPathSkippable } from '../routerConfig'

class AppState {
  _lastPlayerTime?: number
  _subscription?: Subscription

  init() {
    this._subscription = new SubscriptionBuilder()
      .with({
        type: SubscriptionSources.TV_PLATFORM,
        event: PlatformSubscriptionType.BACKGROUND,
        handler: this.onBackground,
      })
      .subscribe()
  }

  destroy() {
    this._subscription?.unsubscribe()
    this._subscription = undefined
  }

  onBackground = () => {
    this._lastPlayerTime = undefined
    const page = Router.getActivePage() as any
    if (page._player) {
      this._lastPlayerTime = page?.currentTime
      page?._analyticsDelegate?.fireVideoEnd({
        endType: 'Background',
      })
    }
    setAppLaunchType(AppLaunchTypes.background)
    const hash = Router.getActiveHash()
    if (hash === ROUTE.boot) return // Avoid infinite loop at app startup
    const returnPath = !hash || isPathSkippable(hash) ? ROUTE.home : hash
    Router.navigate(ROUTE.splash, { returnPath })
  }

  checkPendingEvents = () => {
    if (this._lastPlayerTime === undefined) return
    const page = Router.getActivePage() as any
    page?._analyticsDelegate?.fireVideoStart({
      resumeTime: this._lastPlayerTime,
      launchType: getAppLaunchType(),
    })
    this._lastPlayerTime = undefined
  }
}

export const AppStateManager = new AppState()
