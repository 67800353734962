import BasePlayerControls from './BasePlayerControls'

import LiveToVodButton from '../../buttons/LiveToVodButton'
import { LiveBadge } from './LiveBadge'
import LiveControlsComponent from './LiveControlsComponent'

import { MediaOptionsStateFactory } from './states/MediaOptions'
import { QOSPanelStateFactory } from './states/QOSPanel'
import { LiveControlsStateFactory } from './states/LiveControls'

import LinearStrategy from './strategies/LinearStrategy'
import { LiveToVodButtonStates } from '../../../pages/Player/delegates/LiveToVodDelegate'

import { KeyMap } from '../../../lib/KeyMap'
import Announcer from '../../../lib/tts/Announcer'

import IPlayerControlsStrategy from './strategies/IPlayerControlsStrategy'
import { TimeChangeEvent } from '../../../player/model/event'
import { PlayerControlsStates, TRICK_PLAY_ELEMENTS } from '../../../constants'
import { ProgressHookEvent } from './hooks/useProgress'
import moment from 'moment-timezone'
import PlayerStoreSingleton from '../../../store/PlayerStore/PlayerStore'
import { SingleStream } from '../../../store/PlayerStore/actions'

export default class LinearPlayerControls extends BasePlayerControls {
  _liveToVodEvents: any
  _liveControls: any

  override _strategy: IPlayerControlsStrategy = new LinearStrategy(this)
  static override TITLE_CONTAINER_Y = 720

  override _keyMap = KeyMap(this, [
    [
      [PlayerControlsStates.QOSPanel, PlayerControlsStates.MoreInfo],
      [PlayerControlsStates.MediaOptions, PlayerControlsStates.MoreInfo],
      [PlayerControlsStates.QOSPanel],
    ],
    [[], PlayerControlsStates.LiveControls, []],
    [[], PlayerControlsStates.LiveToVod, []],
    [[], PlayerControlsStates.PlayOrPause, []],
  ])

  static override _template() {
    return super._template({
      LiveToVod: {
        type: LiveToVodButton,
      },
    })
  }

  override _init() {
    super._init()
    this.tag('PlayerControls.TitleContainer.HeadingContainer').childList.addAt(
      { ref: 'Live', type: LiveBadge },
      1
    )

    this.showLiveControls()
  }

  showLiveControls() {
    this.tag('PlayerControls').patch({
      LiveControls: {
        type: LiveControlsComponent,
      },
    })
    this._liveControls = this.tag('LiveControls')
  }

  override _enable() {
    super._enable()
    this._liveToVodEvents?.unsubscribe()
    this._liveToVodEvents = this.fireAncestors('$subscribeToLiveToVodDelegate', this._sync)
    this._setState(PlayerControlsStates.PlayOrPause)
  }

  override _disable() {
    super._disable()
    this._liveToVodEvents?.unsubscribe()
  }

  _sync = ({ status }: any) => {
    const button = this.tag('LiveToVod')
    if (status === LiveToVodButtonStates.EMPTY_STATE) {
      button.visible = false
      if (this._getState() === PlayerControlsStates.LiveToVod) {
        this._setState(PlayerControlsStates.PlayOrPause)
      }
    } else if (!button.visible) {
      button.visible = true
    }
  }

  override _updateProgress(progress: ProgressHookEvent) {
    const [currentTime, duration] = progress
    // Workaround for race condition issue on program boundary change
    if (duration && currentTime > duration) {
      const stream = PlayerStoreSingleton.stream as SingleStream
      const program = PlayerStoreSingleton.program
      if (moment().isBefore(moment(stream?.endTime))) {
        this._strategy.init(stream, program)
      }
    } else {
      super._updateProgress(progress)
    }
  }

  override _playerSubscriptionHandler(event: any) {
    super._playerSubscriptionHandler(event)
    if (event instanceof TimeChangeEvent) {
      /**
       * Toggle "back to live" button based on isAtLiveEdge property from player
       * Sometimes isAtLiveEdge is undefined even if we are on the live edge, so check it for the boolean type.
       */
      if (this.active && typeof event._isAtLiveEdge === 'boolean')
        this._liveControls.toggleBackToLive(!event._isAtLiveEdge)
    }
  }

  static override _states() {
    return [
      class PlayOrPause extends this {
        override _active(): void {
          super._active()
          Announcer.announce(
            this.fireAncestors('$isPlaying')
              ? TRICK_PLAY_ELEMENTS.pauseButton
              : TRICK_PLAY_ELEMENTS.playButton
          )
        }

        override _handleEnter() {
          this.fireAncestors('$playOrPause')
        }
      },
      class LiveToVod extends this {
        override _getFocused() {
          return this.tag('LiveToVod') || this
        }
      },
      QOSPanelStateFactory(this),
      LiveControlsStateFactory(this, 'PlayerControls.LiveControls'),
      MediaOptionsStateFactory(this),
    ]
  }

  override _setRating() {
    this._tvRating.visible = false
  }

  // AdControls not used for linear
  override showAdControls() {}
}
