import { Lightning } from '@lightningjs/sdk'
import SeekablePlayerControls from '../SeekablePlayerControls'
import { AdBreakMarkersDelegate } from '../delegates/AdBreakMarkersDelegate'

export const WithAdBreaks = <T extends Lightning.Component.Constructor<SeekablePlayerControls>>(
  base: T
) =>
  class extends base {
    _adbreakMarkersDelegate = new AdBreakMarkersDelegate(this)

    addAdBreakPoints(adbreaks: any) {
      this._adbreakMarkersDelegate.draw(adbreaks, this._strategy._totalTime)
    }
  }
