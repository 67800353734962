import { Lightning } from '@lightningjs/sdk'
import { NonLinearAdEvent } from '../../../player/model/event/NonLinearAdEvent'
import LaunchDarklySingleton from '../../../lib/launchDarkly/LaunchDarkly'
import LaunchDarklyFeatureFlags from '../../../lib/launchDarkly/LaunchDarklyFeatureFlags'
import SeekablePlayerControls from '../../../components/player/PlayerControls/SeekablePlayerControls'
import { ControlsHasPauseAds } from '../../../components/player/PlayerControls/hoc/WithPauseAds'
import BasePlayer from '../BasePlayer'

export const WithPauseAds = <T extends Lightning.Component.Constructor<BasePlayer>>(component: T) =>
  class extends component {
    override _controls: SeekablePlayerControls & ControlsHasPauseAds

    override _playerEventsHandler(event: any) {
      super._playerEventsHandler(event)
      if (event instanceof NonLinearAdEvent) {
        this._onNonLinearAdEvent(event)
      }
    }

    _onNonLinearAdEvent(event: NonLinearAdEvent): void {
      const pauseAdEnabled =
        LaunchDarklySingleton?.getFeatureFlag(LaunchDarklyFeatureFlags.enablePauseAdsRefresh) ||
        true

      const {
        refreshMax = 3,
        refreshRate = 10,
      }: { refreshMax: number; refreshRate: number | null } =
        LaunchDarklySingleton?.getFeatureFlag(LaunchDarklyFeatureFlags.pauseAdsRefreshCount) || {}

      if (pauseAdEnabled && refreshRate !== null) {
        this._controls.setPauseAdsEventData(event, {
          refreshMax,
          refreshRate,
        })
      }
    }
  }
